.react-contexify {
  position: fixed;
  opacity: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: #fff;
  box-sizing: border-box;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.3), 0 0 0 1px #eee;
  padding: 5px 0;
  min-width: 180px;
}
.react-contexify .react-contexify__submenu {
  position: absolute;
  top: 0;
  pointer-events: none;
  transition: opacity 0.275s;
}
.react-contexify__submenu-arrow {
  font-size: 12px;
  position: absolute;
  right: 10px;
  line-height: 22px;
}
.react-contexify__separator {
  float: left;
  width: 100%;
  height: 1px;
  cursor: default;
  margin: 4px 0;
  background-color: rgba(0, 0, 0, 0.2);
}
.react-contexify__item {
  cursor: pointer;
  position: relative;
}
.react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__item__content {
  color: #fff;
  background-color: #4393e6;
}
.react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__submenu {
  pointer-events: auto;
  opacity: 1;
}
.react-contexify__item--disabled {
  cursor: default;
  opacity: 0.5;
}
.react-contexify__item__content {
  padding: 6px 12px;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
  white-space: nowrap;
  color: #333;
  position: relative;
}
.react-contexify__item__icon {
  font-size: 20px;
  margin-right: 5px;
  font-style: normal;
}
.react-contexify__theme--dark {
  padding: 6px 0;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.4), 0 0 0 1px #222;
}
.react-contexify__theme--dark,
.react-contexify__theme--dark .react-contexify__submenu {
  background-color: rgba(40, 40, 40, 0.98);
}
.react-contexify__theme--dark .react-contexify__separator {
  background-color: #eee;
}
.react-contexify__theme--dark .react-contexify__item__content {
  color: #fff;
}
.react-contexify__theme--dark .react-contexify__item__icon {
  margin-right: 8px;
  width: 12px;
  text-align: center;
}
.react-contexify__theme--light {
  padding: 6px 0;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.2), 0 0 0 1px #eee;
}
.react-contexify__theme--light .react-contexify__separator {
  background-color: #eee;
}
.react-contexify__theme--light .react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__item__content {
  color: #4393e6;
  background-color: #e0eefd;
}
.react-contexify__theme--light .react-contexify__item__content {
  color: #666;
}
.react-contexify__theme--light .react-contexify__item__icon {
  margin-right: 8px;
  width: 12px;
  text-align: center;
}
@keyframes react-contexify__popIn {
  0% {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@keyframes react-contexify__popOut {
  0% {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}
.react-contexify__will-enter--pop {
  animation: react-contexify__popIn 0.3s cubic-bezier(0.51, 0.92, 0.24, 1.2);
}
.react-contexify__will-leave--pop {
  animation: react-contexify__popOut 0.3s cubic-bezier(0.51, 0.92, 0.24, 1.2);
}
@keyframes react-contexify__zoomIn {
  0% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}
@keyframes react-contexify__zoomOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 0;
  }
}
.react-contexify__will-enter--zoom {
  transform-origin: top left;
  animation: react-contexify__zoomIn 0.4s;
}
.react-contexify__will-leave--zoom {
  animation: react-contexify__zoomOut 0.4s;
}
@keyframes react-contexify__fadeIn {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes react-contexify__fadeOut {
  0% {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.react-contexify__will-enter--fade {
  animation: react-contexify__fadeIn 0.3s ease;
}
.react-contexify__will-leave--fade {
  animation: react-contexify__fadeOut 0.3s ease;
}
@keyframes react-contexify__flipInX {
  0% {
    transform: perspective(400px) rotateX(90deg);
    animation-timing-function: ease-in;
  }
  40% {
    transform: perspective(400px) rotateX(-20deg);
    animation-timing-function: ease-in;
  }
  60% {
    transform: perspective(400px) rotateX(10deg);
  }
  80% {
    transform: perspective(400px) rotateX(-5deg);
  }
  to {
    transform: perspective(400px);
  }
}
@keyframes react-contexify__flipOutX {
  0% {
    transform: perspective(400px);
  }
  30% {
    transform: perspective(400px) rotateX(-20deg);
    opacity: 1;
  }
  to {
    transform: perspective(400px) rotateX(90deg);
    opacity: 0;
  }
}
.react-contexify__will-enter--flip {
  animation: react-contexify__flipInX 0.65s;
}
.react-contexify__will-enter--flip,
.react-contexify__will-leave--flip {
  -webkit-backface-visibility: visible!important;
  backface-visibility: visible !important;
}
.react-contexify__will-leave--flip {
  animation: react-contexify__flipOutX 0.65s;
}
